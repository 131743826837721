
import Component, {mixins} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import Plan from '@/mixins/Plan';

import {authStore} from '@/store/__STORE_auth';
import {userStore} from '@/store/__STORE_user';

import QRCode from '@/components_v2/generic/misc/__COMP_QRCode.vue';
import Timer from '@/components_v2/generic/misc/__COMP_Timer.vue';

@Component({
  components: {
    QRCode,
    Timer,
  },
})
/**
 * Component to hold the QR code request generation for XR devices for the platform
 */
export default class XRRequestToken extends mixins(Plan) {
  name: 'XR Request Token'

  showGenerateQR: Boolean = false;
  currentPassword: string = '';
  renderQR: Boolean = false;
  hash: String = '';
  hasXR: Boolean = false;

  /**
   * things to run on mount
   */
  @Watch('__MIXINauthUser')
  updateAuth() {
    this.hasXR = this.__MIXINuserCanAccessHailMary;
  }

  /**
   * Method to set the state of showing the Generate QR.
   */
  generateQRRequested() {
    this.currentPassword = '';
    this.hash = '';
    this.showGenerateQR = !this.showGenerateQR;
    this.renderQR = false;
  }

  /**
   * Function to initiate the QR code
   * @param {string} QRHash
   */
  initiateQR(QRHash: string) {
    this.hash = QRHash;
    this.renderQR = true;
  }

  /**
   * Function to finish out the QR code generation flow
   */
  timerFinished() {
    this.hash = '';
    this.currentPassword = '';
    this.showGenerateQR = false;
    this.renderQR = false;
  }

  /**
   * Pass the form data to the auth store to verify the logged in user data.
   */
  sendGenerateTokenRequest() {
    const data = {
      email: this.auth.email,
      password: this.currentPassword,
    };
    authStore.xrRequestToken(data)
        .then((resp) => {
          this.initiateQR(resp.data.hash);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Password is incorrect, but we need to be careful with how we phrase it.
            this.$toast.error('Unable to process your request.');
            this.showGenerateQR = true;
            // incorrect password
          } else if (err.response.status === 403) {
            // no xr addon
            this.$toast.error('Missing XR addon.');
            this.showGenerateQR = false;
          } else {
            this.$toast.error('Something went wrong.');
            this.showGenerateQR = true;
          }
          this.currentPassword = '';
        });
    this.showGenerateQR = false;
  }

  /**
   * Computed function to stringify JSON object required for QR code
   * @return {string} - the encoded object as a string
   */
  get QRcodeString(): string {
    return JSON.stringify({
      'hash': this.hash,
    });
  }

  /**
   * Get logged in user data
   * @return {any}
   */
  get auth(): any {
    return userStore.__GETTERuserProfile;
  }
}

