<template>
  <div class="section-panel">
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <div class="icon gray">
          <icon-monitor />
        </div>
        <div class="content">
          <h3>Recent calls</h3>
        </div>
      </div>
    </div>
    <div class="section-panel-body">
      <table class="table table-borderless table-data table-hide-last-column">
        <thead v-if="callsList.data.length">
          <tr>
            <th>Host</th>
            <th>Duration</th>
            <th>Room</th>
            <th>Participants</th>
            <th>Quality</th>
          </tr>
        </thead>
        <tbody v-if="callsList.data.length">
          <tr
            v-for="call in callsList.data"
            :key="call.id"
            v-tooltip.left="{content: toolTipContent(call)}"
          >
            <td>
              {{ call.host.email }}
            </td>
            <td>
              {{ call.start_time | moment('DD/MM/YYYY HH:mm') }} - {{ call.end_time | moment('HH:mm') }}
            </td>
            <td>
              <template
                v-if="call.meeting_room.deleted"
              >
                {{ call.meeting_room.webrtc.alias }}
              </template>
              <template v-else>
                <router-link
                  target="_blank"
                  :to="{name: 'welcome-guest', params: {webrtcAlias: call.meeting_room.webrtc.alias, preloadedMeetingRoom: call.meeting_room }}"
                >
                  {{ call.meeting_room.webrtc.alias }}
                </router-link>
              </template>
            </td>
            <td>
              {{ call.participant_count }}
            </td>
            <td>
              {{ call.quality }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>No calls</td>
          </tr>
        </tbody>
      </table>
      <pagination
        :paginator="callsList"
        @load-page="loadPage"
      />
    </div>
  </div>
</template>
<script>
import {superAdminStore} from '@/store/__STORE_superAdmin';
import Pagination from '@/components/SuperAdmin/Pagination.vue';
import IconMonitor from '@/components/icons/IconMonitor.vue';

export default {
  components: {
    Pagination,
    IconMonitor,
  },
  computed: {
    callsList: () => superAdminStore.callsList,
  },
  mounted() {
    this.loadPage(1);
  },
  methods: {
    loadPage(pageNum) {
      superAdminStore.getCallsList(pageNum);
    },
    toolTipContent(call) {
      if (call.meeting_room.deleted) {
        return 'This user has been deleted therefore their room is not accessible';
      }
      return '';
    },
  },
};
</script>
