<template>
  <div
    v-if="paginator.last_page > 1"
    class="row"
  >
    <div class="col-12 p-3">
      <label class="float-right font-weight-bold m-2">
        {{ paginator.total }} item{{ paginator.total > 1 ? 's' : '' }},
        {{ paginator.last_page }} page{{ paginator.last_page > 1 ? 's' : '' }}
      </label>
      <div class="pagination float-right">
        <button
          class="btn btn-outline fas fa-step-backward"
          :disabled="paginator.current_page === 1"
          @click="loadPage(1)"
        />
        <button
          class="btn btn-outline fas fa-angle-left"
          :disabled="paginator.current_page === 1"
          @click.prevent="loadPage(paginator.current_page-1)"
        />
        <input
          v-model="currentPage"
          type="text"
          class="text-center"
          @keyup.enter="loadPage(paginator.current_page)"
        >
        <button
          class="btn btn-outline fas fa-angle-right"
          :disabled="paginator.current_page === paginator.last_page"
          @click.prevent="loadPage(paginator.current_page+1)"
        />
        <button
          class="btn btn-outline fas fa-step-forward"
          :disabled="paginator.current_page === paginator.last_page"
          @click.prevent="loadPage(paginator.last_page)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    paginator: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentPage: {
      get: function() {
        return this.paginator.currentPage;
      },
      set: function(newValue) {
        this.$emit('update:paginator.currentPage', newValue);
      },
    },
  },
  methods: {
    loadPage(pageNum) {
      this.$emit('load-page', pageNum);
    },
  },
};
</script>
