import axios from 'axios';
import {Module, Action, Mutation, VuexModule} from 'vuex-class-modules';
import store from './';
import {mainStore} from './__STORE_main';
axios.defaults.headers = {
  ...axios.defaults.headers,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

/**
 *
 */
@Module
class SuperAdminStore extends VuexModule {
  // State
  usersList = {
    data: [],
  }
  callsList = {
    data: [],
  }
  // Mutations

  /**
   *
   * @param {any} value
   */
  @Mutation
  private __setUsersList(value: any) {
    this.usersList = value;
  }

  /**
   *
   * @param {any} value
   */
  @Mutation
  private __setCallsList(value: any) {
    this.callsList = value;
  }
  // Actions

  /**
   * Fetch sign up user list for super admin users
   * @param {number} [page] - optional give page to fetch
   * @return {Promise} - api response
   */
  @Action
  async getUsersList(page?: number): Promise<any> {
    mainStore.setLoading();
    return new Promise((resolve, reject) => {
      let url = '/super-admin/users';
      if (page) {
        url += '?page=' + page;
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            this.__setUsersList(response.data);
            mainStore.setStatus('');
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   * Fetch global call list for super admin users
   * @param {number} [page] - optional give page to fetch
   * @return {Promise} - api response
   */
  @Action
  async getCallsList(page?: number): Promise<any> {
    mainStore.setLoading();
    return new Promise((resolve, reject) => {
      let url = '/super-admin/calls';
      if (page) {
        url += '?page=' + page;
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            this.__setCallsList(response.data);
            mainStore.setStatus('');
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }
  // Getters
}

export const superAdminStore = new SuperAdminStore({store, name: 'superAdminStore'});
