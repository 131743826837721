<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <div class="section-header">
          <h1>Dashboard</h1>
        </div>
        <div class="row row-has-panel">
          <div class="col-lg-6">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <div class="icon gray">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <path
                        fill="currentColor"
                        d="M16,22 L8,22 C7.44771525,22 7,21.5522847 7,21 C7,20.4871642 7.38604019,20.0644928 7.88337887,20.0067277 L8,20 L11,20 L11,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,5 C1,3.34314575 2.34314575,2 4,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,15 C23,16.6568542 21.6568542,18 20,18 L13,18 L13,20 L16,20 C16.5128358,20 16.9355072,20.3860402 16.9932723,20.8833789 L17,21 C17,21.5128358 16.6139598,21.9355072 16.1166211,21.9932723 L16,22 L8,22 L16,22 Z M20,4 L4,4 C3.44771525,4 3,4.44771525 3,5 L3,15 C3,15.5522847 3.44771525,16 4,16 L20,16 C20.5522847,16 21,15.5522847 21,15 L21,5 C21,4.44771525 20.5522847,4 20,4 Z"
                      />
                    </svg>
                  </div>
                  <div class="content">
                    <h3>Host a meeting now</h3>
                  </div>
                </div>
              </div>
              <div class="section-panel-body text-center">
                <router-link
                  v-if="!isUserOnNoLicense"
                  :to="{name: 'host', params: { webrtcAlias: webrtcAlias}}"
                >
                  <button
                    id="host-now"
                    class="btn btn-primary mb-1"
                  >
                    Host now
                  </button>
                </router-link>
                <p v-else-if="authUser.role === 'Admin'">
                  You are unable to host meetings as you do not have a license. <br>
                  Go to <router-link :to="{name: 'admin'}">
                    User Management
                  </router-link> to assign yourself a license.
                </p>
                <p v-else>
                  You are unable to host meetings as you do not have a license. <br>
                  Contact your account admin to resolve this.
                </p>
              </div>
            </div>
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title d-flex flex-row w-100">
                  <div class="icon gray">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <path
                        fill="currentColor"
                        d="M16,22 L8,22 C7.44771525,22 7,21.5522847 7,21 C7,20.4871642 7.38604019,20.0644928 7.88337887,20.0067277 L8,20 L11,20 L11,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,5 C1,3.34314575 2.34314575,2 4,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,15 C23,16.6568542 21.6568542,18 20,18 L13,18 L13,20 L16,20 C16.5128358,20 16.9355072,20.3860402 16.9932723,20.8833789 L17,21 C17,21.5128358 16.6139598,21.9355072 16.1166211,21.9932723 L16,22 L8,22 L16,22 Z M20,4 L4,4 C3.44771525,4 3,4.44771525 3,5 L3,15 C3,15.5522847 3.44771525,16 4,16 L20,16 C20.5522847,16 21,15.5522847 21,15 L21,5 C21,4.44771525 20.5522847,4 20,4 Z"
                      />
                    </svg>
                  </div>
                  <div class="content">
                    <router-link
                      :to="{name: 'meetings'}"
                      class="branded-link"
                    >
                      <div class="w-70">
                        <h3>My meetings</h3>
                      </div>
                    </router-link>
                  </div>
                  <router-link
                    v-if="!isUserOnNoLicense"
                    :to="{name: 'meeting'}"
                    class="btn btn-secondary d-flex  align-items-center ml-auto"
                  >
                    <icon-plus class="mr-2 d-flex gray" />Schedule a meeting
                  </router-link>
                </div>
              </div>
              <div class="section-panel-body">
                <table class="table table-borderless table-data">
                  <tbody v-if="upcomingMeetingList.data && upcomingMeetingList.data.length > 0">
                    <tr
                      v-for="meeting in upcomingMeetingList.data"
                      :key="meeting.id"
                    >
                      <td>
                        <a
                          :href="meetingRoomDirectLink(meeting.meeting_room.webrtc.alias)"
                          target="_blank"
                        >{{ meeting.subject }}</a>
                        <span
                          class="time"
                        >{{ }} {{ meeting.starts_at | moment('dddd, D MMMM h:mm A') }}</span>
                      </td>
                      <td>
                        <meeting-invites-dropdown :meeting="meeting">
                          <a
                            :id="'sendInvites-' + meeting.id"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                          >Send invites</a>
                        </meeting-invites-dropdown>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="isUserOnNoLicense">
                    <tr v-if="authUser.role === 'Admin'">
                      <td>
                        You do not have a license, this feature is not available to you.<br>
                        Go to <router-link :to="{name: 'admin'}">
                          User Management
                        </router-link> to assign yourself a license.
                      </td>
                    </tr>
                    <tr v-else>
                      You do not have a license, this feature is not available to you.<br>
                      Contact your account admin to resolve this.
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td>No upcoming meetings</td>
                    </tr>
                  </tbody>
                </table>

                <div class="mt-1 text-center text-grey-light">
                  <router-link :to="{name: 'settings'}">
                    <small
                      v-if="upcomingMeetingList.data && upcomingMeetingList.data.length"
                    >Timezone {{ auth.timezone.replace('_', ' ') }}</small>
                  </router-link>
                </div>

                <div
                  v-if="upcomingMeetingList.last_page > 1"
                  class="row"
                >
                  <div class="col-12 p-3">
                    <label class="float-right font-weight-bold m-2">
                      {{ upcomingMeetingList.total }} item{{ upcomingMeetingList.total > 1 ? 's' : '' }},
                      {{ upcomingMeetingList.last_page }} page{{ upcomingMeetingList.last_page > 1 ? 's' : '' }}
                    </label>
                    <div class="pagination float-right">
                      <button
                        class="btn btn-outline fas fa-step-backward"
                        :disabled="curPage === 1"
                        @click="go(1)"
                      />
                      <button
                        class="btn btn-outline fas fa-angle-left"
                        :disabled="curPage === 1"
                        @click.prevent="go(curPage-1)"
                      />
                      <input
                        v-model="curPage"
                        type="text"
                        class="text-center"
                        @keyup.enter="go(curPage)"
                      >
                      <button
                        class="btn btn-outline fas fa-angle-right"
                        :disabled="curPage === upcomingMeetingList.last_page"
                        @click.prevent="go(curPage+1)"
                      />
                      <button
                        class="btn btn-outline fas fa-step-forward"
                        :disabled="curPage === upcomingMeetingList.last_page"
                        @click.prevent="go(upcomingMeetingList.last_page)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              v-if="authUser && authUser.is_trial"
              class="my-4"
            >
              Your trial expires in {{ authUser.trial_days_left }} day{{ authUser.trial_days_left > 1 ? 's' : '' }}
            </p>

            <p
              v-if="authUser && authUser.account && authUser.account.billing_plan.name === 'Free' && authUser.trial_expired_at"
              class="my-4"
            >
              Trial expired at {{ authUser.trial_expired_at }}
            </p>
          </div>
          <div class="col-lg-6">
            <XRRequestToken v-if="auth" />
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <div class="icon gray">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <path
                        fill="currentColor"
                        d="M16,22 L8,22 C7.44771525,22 7,21.5522847 7,21 C7,20.4871642 7.38604019,20.0644928 7.88337887,20.0067277 L8,20 L11,20 L11,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,5 C1,3.34314575 2.34314575,2 4,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,15 C23,16.6568542 21.6568542,18 20,18 L13,18 L13,20 L16,20 C16.5128358,20 16.9355072,20.3860402 16.9932723,20.8833789 L17,21 C17,21.5128358 16.6139598,21.9355072 16.1166211,21.9932723 L16,22 L8,22 L16,22 Z M20,4 L4,4 C3.44771525,4 3,4.44771525 3,5 L3,15 C3,15.5522847 3.44771525,16 4,16 L20,16 C20.5522847,16 21,15.5522847 21,15 L21,5 C21,4.44771525 20.5522847,4 20,4 Z"
                      />
                    </svg>
                  </div>
                  <div class="content">
                    <h3>AI Bot</h3>
                  </div>
                </div>
              </div>
              <div class="section-panel-body text-center">
                <button
                  id="host-now"
                  class="btn btn-primary mb-1"
                  @click.prevent="isShowChatBot = !isShowChatBot"
                >
                  Chat now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="auth && auth.is_super_admin">
          <div class="section-header mt-2">
            <h1>Admin</h1>
          </div>

          <div class="row row-has-panel">
            <div class="col-lg-12">
              <users-table />
            </div>
          </div>

          <div class="row row-has-panel">
            <div class="col-lg-12">
              <calls-table />
            </div>
          </div>
        </div>
        <div
          v-if="isShowChatBot"
          id="modal-calendar-integration"
          class="f-modal"
        >
          <div class="f-modal-table">
            <div class="f-modal-cell align-middle">
              <chat-bot
                v-if="isShowChatBot"
                :dashboardBot="'dashboard'"
                @close="closeIsShowChatBot"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="auth && auth.must_update_payment_details">
        <router-link
          v-if="canAuthUserAccessAdminFeatures"
          :to="{name: 'billing'}"
          class="notices is-bottom"
        >
          <div
            role="alert"
            class="toast toast-warning is-bottom-right"
          >
            Your payment details need updating. Click here to update your details.
          </div>
        </router-link>

        <div
          v-else
          class="notices is-bottom"
        >
          <div
            role="alert"
            class="toast toast-warning is-bottom-right"
          >
            Your payment details need updating. Contact an admin to resolve this issue.
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {userStore} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';

import MeetingInvitesDropdown from '@/components/MeetingInvitesDropdown.vue';
import DirectLinkMixin from '@/mixins/DirectLinkMixin';
import IconPlus from '@/components/icons/IconPlus.vue';
import PlanMixin from '@/mixins/PlanMixin';
import UsersTable from '@/components/SuperAdmin/UsersTable.vue';
import CallsTable from '@/components/SuperAdmin/CallsTable.vue';
import XRRequestToken from '@/components_v2/intelligent/dashboard/__COMP_XRRequestToken.vue';
import ChatBot from '@/components/OpenAIChatBot.vue';

export default {
  name: 'Home',
  components: {
    UsersTable,
    CallsTable,
    MeetingInvitesDropdown,
    IconPlus,
    XRRequestToken,
    ChatBot,
  },
  mixins: [DirectLinkMixin, PlanMixin],
  props: {
    reloadRequired: Boolean,
  },
  data: () => ({
    curPage: '',
    isShowChatBot: false,
  }),
  computed: {
    auth() {
      return userStore.profileData;
    },
    upcomingMeetingList() {
      return meetingStore.upcomingMeetings;
    },
    webrtcAlias() {
      if (this.auth && this.auth.meeting_room) {
        return this.auth.meeting_room.webrtc.alias;
      }
      return '';
    },
  },
  watch: {
    upcomingMeetingList(val) {
      this.curPage = val.current_page;
    },
  },
  created() {
    meetingStore.getUpcomingMeetingsData();
    userStore.getUserSettings();
    localStorage.removeItem('voiceMessages');
  },
  mounted() {
    this.$emit('disable-fullscreen');
    if (this.reloadRequired) {
      // Otherwise Pexip doesn't release the camera and webcam light stays on
      window.location.reload();
    }
    meetingStore.getStorage();
  },
  methods: {
    go(pageNum) {
      meetingStore.getUpcomingMeetingsData(pageNum);
    },
    changeFavicon() {
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      if (this.auth.meeting_room && this.auth.meeting_room.brand.favicon) {
        link.href = this.auth.meeting_room.brand.favicon;
      } else {
        link.href = '';
      }
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    },
    closeIsShowChatBot() {
      this.isShowChatBot=false;
      localStorage.removeItem('dashboardVoiceMessages');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-warning {
  color: darkred;
  font-size: 1.4rem;
  padding: 20px 15px;
}

.is-bottom-right {
  float:right;
}
</style>
