<template>
  <div class="section-panel">
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <div class="icon gray">
          <icon-monitor />
        </div>
        <div class="content">
          <h3>Registered users</h3>
        </div>
      </div>
    </div>
    <div class="section-panel-body">
      <table class="table table-borderless table-data table-hide-last-column">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Room ID</th>
            <th>Licence</th>
            <th>Addons</th>
            <th>Registered</th>
          </tr>
        </thead>
        <tbody v-if="usersList.data.length">
          <tr
            v-for="user in usersList.data"
            :key="user.id"
          >
            <td>
              <i
                v-if="user.is_pending"
                v-tooltip="{content: 'Pending invitation', classes: ['tooltip-danger']}"
                class="fa fa-info-circle mx-1 text-danger"
              />
              <i
                v-if="user.is_blocked"
                v-tooltip="{ content: 'User blocked'}"
                class="fas fa-ban"
              />
              {{ user.name }} {{ user.last_name }}
            </td>

            <td>{{ user.is_pending ? user.invite_emailed_to : user.email }}</td>
            <td>
              <span v-if="user.meeting_room">
                <router-link
                  target="_blank"
                  :to="{name: 'welcome-guest', params: {webrtcAlias: user.meeting_room.webrtc.alias, preloadedMeetingRoom: user.meeting_room }}"
                >
                  {{ user.meeting_room.webrtc.alias }}
                </router-link>
              </span>
            </td>
            <td>
              {{ user.billing_plan ? user.billing_plan.name : '' }}
            </td>
            <td>
              <span v-if="!user.is_pending">
                <div
                  v-for="addon in user.addons"
                  :key="addon.id"
                >
                  {{ addon.name }}
                </div>
                <span v-if="!user.addons.length">
                  No addons
                </span>
              </span>
            </td>
            <td>
              {{ user.created_at | moment('DD/MM/YYYY HH:mm') }}
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        :paginator="usersList"
        @load-page="loadPage"
      />
    </div>
  </div>
</template>
<script>
import {superAdminStore} from '@/store/__STORE_superAdmin';
import Pagination from '@/components/SuperAdmin/Pagination.vue';
import IconMonitor from '@/components/icons/IconMonitor.vue';

export default {
  components: {
    Pagination,
    IconMonitor,
  },
  computed: {
    usersList: () => superAdminStore.usersList,
  },
  mounted() {
    this.loadPage(1);
  },
  methods: {
    loadPage(pageNum) {
      superAdminStore.getUsersList(pageNum);
    },
  },
};
</script>
