
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync, Watch, Emit} from 'vue-property-decorator';

import {intervalToDuration} from 'date-fns';

@Component
/**
 * Component for doing a count down timer for a given number of minutes
 */
export default class Timer extends Vue {
  @PropSync('seconds', {type: Number, required: true}) readonly __PROPseconds: number

  name: 'Timer'

  milliseconds = 0;

  /**
   * Watch function that checks to see if the timer has completed
   */
  @Watch('milliseconds')
  __handleZero() {
    if (this.milliseconds <= 0) {
      this.__EMITfinished();
    }
  }

  /**
   * Function is called upon component mount, sets the component up to begin count down
   */
  mounted() {
    this.milliseconds = this.__PROPseconds * 1000;
    setTimeout(() => {
      this.reduceTimer();
    }, 1000);
  }

  /**
   * Decrements the timer down by 1 second every second
   */
  reduceTimer() {
    if (this.milliseconds === 0) {
      return;
    }
    this.milliseconds -= 1000;
    setTimeout(() => {
      this.reduceTimer();
    }, 1000);
  }

  /**
   * Formats the timer output to M:ss
   */
  get formatTimer() {
    const duration = intervalToDuration({start: 0, end: this.milliseconds});

    const seconds = duration.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2});

    return `${duration.minutes}:${seconds}`;
  }

  /**
   * Emits when the timer is finished so parent can take action
   */
  @Emit('finished')
  __EMITfinished(): void {}
}

